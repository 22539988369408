<template>
    <div>
        <div class="top_div" v-if="!isadmin">
            <vue-particles color="#dedede" class="canvas_div"></vue-particles>
            <div class="login_container">
                <div class="login_box">
                    <!-- 登录表单区域 -->
                    <div class="tetle">超级管理员登录</div>
                    <el-form
                        :inline="true"
                        :model="loginForm"
                        ref="loginFormRef3"
                        class="pos"
                        label-width="65px"
                        :rules="loginFormRules"
                    >
                        <el-form-item label="手机号" prop="phonenum">
                            <el-input v-model="loginForm.phonenum"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input
                                v-model="loginForm.password"
                                type="password"
                            ></el-input>
                        </el-form-item>
                        <!-- 按钮区域 -->
                        <el-form-item class="btns">
                            <el-button
                                class="btn1"
                                type="primary"
                                @click="login"
                            >
                                登录
                            </el-button>
                            <el-button
                                class="btn1"
                                type="info"
                                @click="resetLoginForm"
                            >
                                重置
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <div v-else-if="isadmin">
            <div>
                <div style="width: 300px; margin: 10px; display: flex">
                    <el-input
                        placeholder="请输入手机号搜索"
                        v-model="searchPhone"
                    >
                        <i
                            slot="suffix"
                            class="el-input__icon el-icon-search"
                            @click="Phonesearch"
                        ></i>
                    </el-input>
                    <el-button
                        class="refresh_btn"
                        size="small"
                        type="success"
                        plain
                        @click="refreshList()"
                    >
                        重新载入
                    </el-button>
                </div>
                <div style="width: 900px; margin-left: 10px">
                    <el-table :data="tableData" style="width: 100%">
                        <el-table-column
                            prop="id"
                            label="id"
                            width="180"
                        ></el-table-column>
                        <el-table-column
                            prop="name"
                            label="用户名"
                            width="180"
                        ></el-table-column>
                        <el-table-column
                            prop="phone"
                            label="电话"
                            width="180"
                        ></el-table-column>
                        <el-table-column
                            prop="role"
                            label="权限"
                            width="180"
                        ></el-table-column>
                        <el-table-column prop="handle" label="操作" width="180">
                            <template slot-scope="scope">
                                <el-button
                                    type="warning"
                                    @click="giveRole(scope.row)"
                                >
                                    权限管理
                                    <i class="el-icon-document"></i>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="pagination_div">
                    <el-pagination
                        background
                        :hide-on-single-page="pageTotal < 5"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="sizes,prev, pager, next"
                        :total="pageTotal"
                        :page-sizes="[5, 10, 20, 30, 50]"
                        :page-size="pageSize"
                        :current-page.sync="pagePosition"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="checkdialogVisible"
            title="分配角色"
            width="30%"
        >
            <div style="font-size: 20px">当前角色：{{ currentRole }}</div>
            <template #footer>
                <span class="dialog-footer">
                    <div style="width: 200px; margin-right: 10px">
                        <el-select
                            v-model="rolevalue"
                            clearable
                            placeholder="请分配角色"
                        >
                            <el-option
                                v-for="item in roleoptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <el-button @click="checkdialogVisible = false">
                        取 消
                    </el-button>
                    <el-button type="primary" @click="saveRole">确定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import md5 from "js-md5";
import { pwdLogin } from "@/api";
import { getUserInfoPage, updateUserSuRole } from "@/api/login";
import { phoneReg } from "@/utils/config";

export default {
    data() {
        return {
            isadmin: false,
            second: "60",
            // 这是登录表单的数据绑定对象
            loginForm: {
                phonenum: "",
                password: "",
            },
            // 这是表单的验证规则对象
            loginFormRules: {
                // 验证用户名是否合法
                username: [
                    {
                        required: true,
                        message: "请输入用户名称",
                        trigger: "blur",
                    },
                    {
                        min: 3,
                        max: 10,
                        message: "长度在 3 到 10 个字符",
                        trigger: "blur",
                    },
                ],
                // 验证密码是否合法
                password: [
                    {
                        required: true,
                        message: "请输入登录密码",
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        max: 15,
                        message: "长度在 6 到 15 个字符",
                        trigger: "blur",
                    },
                ],
                phonenum: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur",
                    },
                    {
                        min: 11,
                        max: 11,
                        message: "请输入11位手机号码",
                        trigger: "blur",
                    },
                    {
                        pattern: phoneReg,
                        message: "请输入正确的手机号码",
                    },
                ],
            },
            tableData: [],
            pageTotal: 0,
            pageSize: 5,
            pagePosition: 1,
            searchPhone: "",
            checkdialogVisible: false,
            currentRole: "",
            currentId: 0,
            rolevalue: "",
            roleoptions: [
                {
                    value: "admin",
                    label: "admin",
                },
                {
                    value: "consumer",
                    label: "consumer",
                },
            ],
        };
    },
    methods: {
        // 点击重置按钮，重置登录表单
        resetLoginForm() {
            this.$refs.loginFormRef3.resetFields();
        },
        login() {
            var that = this;
            var data = {
                phone: this.loginForm.phonenum,
                // username: this.loginInform.username,
                password: md5(this.loginForm.password),
            };
            pwdLogin(data).then((res) => {
                console.log(res);
                var data = res.data;
                if (data.meta.status == 200) {
                    that.loginForm = {
                        userTel: "",
                        // username: '',
                        password: "",
                    };
                    if (data.data.role == "admin") {
                        that.$message.success("登录成功");
                        that.isadmin = true;
                        that.getUserListByPage();
                    } else {
                        that.$message.warnning("该用户不是超级管理员");
                    }
                } else if (data.meta.status == 400) {
                    that.$message.error(data.meta.msg);
                }
            });
        },
        //修改分页的pagesize
        handleSizeChange(val) {
            this.pageSize = val;
            this.pagePosition = 1;
            this.getUserListByPage();
        },
        //分页
        handleCurrentChange(val) {
            this.pagePosition = val;
            this.getUserListByPage();
        },
        getUserListByPage() {
            var that = this;
            var data = {
                pagenum: this.pagePosition,
                // username: this.loginInform.username,
                pagesize: this.pageSize,
                phone: this.searchPhone,
            };
            // console.log(data)
            // this.$axios3.get('v1/GetUserInfoPage', { params: data })
            getUserInfoPage(data).then((res) => {
                // console.log(res)
                var data = res.data;
                if (data.meta.status == 200) {
                    that.dealTableData(data.data.users);
                    that.pageTotal = data.data.total;
                } else if (data.meta.status == 400) {
                    that.$message.error(data.meta.msg);
                }
            });
        },
        dealTableData(Arr) {
            this.tableData.splice(0, this.tableData.length);
            Arr.forEach((item) => {
                var data = {};
                data.id = item.Id;
                data.phone = item.Phone;
                data.role = item.Role;
                data.name = item.Name;
                this.tableData.push(data);
            });
        },
        refreshList() {
            this.searchPhone = "";
            this.pagePosition = 1;
            this.getUserListByPage();
        },
        giveRole(data) {
            this.currentRole = data.role;
            this.currentId = data.id;
            this.checkdialogVisible = true;
        },
        saveRole() {
            var that = this;
            var data = {
                uid: this.currentId,
                role: this.rolevalue,
                cid: this.$store.state.user.userId,
            };
            // this.$axios3.post('v1/UpdateUserSuRole', data)
            updateUserSuRole(data).then((res) => {
                var data = res.data;
                if (data.meta.status == 200) {
                    that.$message.success(data.meta.msg);
                    that.checkdialogVisible = false;
                    that.rolevalue = "";
                    that.getUserListByPage();
                } else if (data.meta.status == 400) {
                    that.$message.error(data.meta.msg);
                }
            });
        },
        Phonesearch() {
            this.pagePosition = 1;
            this.getUserListByPage();
        },
    },
};
</script>

<style lang="stylus" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100%;
}

.login_box {
  width: 260px;
  height: 350px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  display: flex;
  justify-content: flex-start;

  span {
    padding: 0 20px;
    text-align: left;
  }
}

.btn1 {
  transform: translate(100%);
}

.top_div {
  background-image: linear-gradient(var(--RED-C11C20), var(--RED-79031D), var(--RED-68020F));
}

.canvas_div {
  height: 100vh;
  width: 100%;
}

.pos {
  margin-top: 30px;
  transform: translate(5%);
}

.tetle {
  cursor: pointer;
  width: 100%;
  height: 6rem;
  line-height: 6rem;
  font-family: ' Microsoft Yahei', serif, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background: #ff9807;
}

.pagination_div {
  width: 50%;
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dialog-footer {
  display: flex;
}
</style>
